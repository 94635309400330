<template>
  <b-container class="budget-multiple-accounts-table-amounts-filter px-0">
    <template v-if="isFilterFormShown">
      <b-button class="cancel-left" @click="close">&#x2716;</b-button>
      <b-row class="bordered-table mt-3" no-gutters>
        <b-col cols="12">
          <h4>{{ $t(translationPath + 'title') }}</h4>
          <p>{{ $t(translationPath + 'description') }}</p>
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="mb-4"
                  :label="$t('common.budget')"
                  label-for="date-input"
                  label-cols='3'
                  label-cols-md="2"
                  label-cols-lg="1"
                  label-class="font-weight-bold labelVertical"
                >
                  <b-form-select
                    id="date-input"
                    v-model="budgetId"
                    :options="budgetsOptions"
                    @change="apply"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="4">
                <b-form-group
                  class="mb-4"
                  :label="$t('common.year')"
                  label-for="date-input"
                  label-cols='4'
                  label-class="font-weight-bold labelVertical"
                >
                  <b-form-select
                    id="date-input"
                    v-model="year"
                    :options="yearOptions"
                    @change="apply"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4">
                <b-form-group
                  class="mb-4"
                  :label="$t('common.month')"
                  label-for="date-input"
                  label-cols='4'
                  label-class="font-weight-bold labelVertical"
                >
                  <b-form-select
                    id="date-input"
                    v-model="month"
                    :options="monthOptions"
                    @change="apply"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4">
                <b-form-group
                  class="mb-4"
                  :label="$t(translationPath + (currentUser.strict_accounting_mode ? 'account' : 'category') + '_type')"
                  label-for="account-type-input"
                  label-cols='4'
                  label-class="font-weight-bold labelVertical"
                >
                  <b-form-select
                    id="account-type-input"
                    v-model="accountType"
                    :options="accountTypeOptions"
                    @change="apply"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="currentUser.strict_accounting_mode ? $t('common.account') : $t('common.category')"
                  label-for='accounts'
                  label-cols='3'
                  class='mb-2'
                  label-class="font-weight-bold labelVertical"
                >
                  <custom-select
                    v-model="account"
                    :options="accountOptions"
                    :displayGroupWithTitle="true"
                    groupingValues="accounts"
                    groupingLabel="name"
                    groupingValuesSecondary="groupAccounts"
                    groupingLabelSecondary="text"
                    :placeholder="$t(translationPath + (currentUser.strict_accounting_mode ? 'account' : 'category') + '_placeholder') "
                    :search-placeholder="$t('common.type_to_filter')"
                    :noResultText="$t('common.no_option_found')"
                    :loading="loadingAccounts"
                    :sortGroupLabel="false"
                    label="text"
                    @input="apply"
                  >
                  </custom-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="text-right">
                <b-button class="btn" variant="outline-danger" @click="reset">{{ $t(translationPath + 'reset') }}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import CustomSelect from '@/components/common/CustomSelect'

export default {
  name: 'BudgetMultipleAccountsTableAmountsFilter',
  components: { CustomSelect },
  props: {
    options: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      translationPath: 'budget.multiple_accounts.amounts_filter.',
      year: null,
      month: null,
      accountType: null,
      account: null,
      budgetId: null,
      loadingAccounts: true,
      accounts: [],
      budgets: [],
      isFilterFormShown: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    yearOptions () {
      const years = []
      if (Object.prototype.hasOwnProperty.call(this.options, 'years')) {
        this.options.years.map(year => {
          years.push(year)
        })
      }
      years.sort().reverse()
      years.unshift({ value: null, text: this.$t(this.translationPath + 'all_years') })
      return years
    },
    monthOptions () {
      const months = [{ value: null, text: this.$t(this.translationPath + 'all_months') }]
      for (let m = 1; m <= 12; m++) {
        months.push({ value: m, text: m })
      }
      return months
    },
    accountTypeOptions () {
      return [
        { value: null, text: this.$t(this.translationPath + 'all_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category') + '_types') },
        { value: 'income', text: this.$t('common.income') },
        { value: 'expense', text: this.$t('common.expense') }
      ]
    },
    accountOptions () {
      const options = []

      const accountsTyped = {
        income: this.accounts.filter(a => a.type === 'income'),
        expense: this.accounts.filter(a => a.type === 'expense'),
        asset: this.accounts.filter(a => a.type === 'asset'),
        liability: this.accounts.filter(a => a.type === 'liability')
      }

      const oGroups = {
        income: {},
        expense: {},
        asset: {},
        liability: {}
      }
      const aGroups = {
        income: [],
        expense: [],
        asset: [],
        liability: []
      }
      this.accounts.map(acc => {
        if (!Object.prototype.hasOwnProperty.call(oGroups[acc.type], acc.group_id)) {
          oGroups[acc.type][acc.group_id] = {
            text: acc.account_group.title,
            accounts: []
          }
        }

        oGroups[acc.type][acc.group_id].accounts.push({
          account_group: acc.account_group,
          group_id: acc.account_group.id,
          group_title: acc.account_group.title,
          group_code: acc.account_group.code,
          label: acc.account_group.title,
          text: acc.title,
          type: acc.type,
          id: acc.id,
          value: {
            text: acc.title,
            value: acc.id
          }
        })
      })

      for (const type in oGroups) {
        for (const gi in oGroups[type]) {
          aGroups[type].push(oGroups[type][gi])
        }
      }

      for (const type in accountsTyped) {
        const accounts = []
        if (accountsTyped[type].length) {
          accountsTyped[type].map(acc => {
            accounts.push({
              account_group: acc.account_group,
              group_id: acc.account_group.id,
              group_title: acc.account_group.title,
              group_code: acc.account_group.code,
              label: acc.account_group.title,
              text: acc.title,
              type: acc.type,
              id: acc.id,
              value: {
                text: acc.title,
                value: acc.id
              }
            })
          })
          options.push({
            name: this.$t('common.' + type),
            accounts: accounts,
            groupAccounts: aGroups[type]
          })
        }
      }

      return options
    },
    budgetsOptions () {
      const opts = []
      this.budgets.map(budget => {
        opts.push({ text: budget.title, value: budget.id })
      })
      return opts
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
      .then(response => {
        this.budgets = response.data.data
        this.preselectBudget()
      })
      .catch(err => {
        console.error(err)
      })

    axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?limit=1000&account_type=income,expense,asset,liability`)
      .then(response => {
        this.accounts = response.data.data
        this.loadingAccounts = false
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    reset () {
      if (this.year === null && this.month && this.account === null) {
        return false
      }

      this.year = null
      this.month = null
      this.accountType = null
      this.account = null
      this.apply()
    },
    apply () {
      const data = {
        account_type: this.accountType,
        account_id: this.account ? this.account.id : null,
        year: this.year,
        month: this.month,
        budget_id: this.budgetId
      }
      this.$emit('apply', data)
    },
    preselectBudget () {
      if (this.$route.query.bid) {
        const bid = Number(this.$route.query.bid)
        const validBids = []
        this.budgets.map(budget => {
          validBids.push(budget.id)
        })
        if (validBids.indexOf(bid) !== -1) {
          this.budgetId = Number(this.$route.query.bid)
        }
      }

      if (!this.budgetId && this.currentCOA.locale) {
        this.budgetId = this.currentCOA.default_budget_id
      }
    },
    close () {
      this.reset()
      this.isFilterFormShown = false
    },
    open () {
      this.isFilterFormShown = true
    },
    toggle () {
      if (!this.isFilterFormShown) {
        this.open()
      } else {
        this.close()
      }
    }
  },
  watch: {
    'currentCOA.locale' () {
      if (!this.budgetId) {
        this.preselectBudget()
      }
    }
  }
}
</script>

<style lang="scss">
.budget-multiple-accounts-table-amounts-filter {
  position: relative;
  .cancel-left {
    color: #fff;
    padding: 0;
    position: absolute;
    right: -5px;
    top: -5px;
    border: 1px solid #c5c5c5;
    background: #c5c5c5;
    border-radius: 60px;
    width: 26px;
    text-align: center;
  }
}
</style>
