<template>
  <b-container class="budget-multiple-accounts-table-incomes-expenses px-0">
    <budget-multiple-accounts-table-amounts-filter
      ref="FilterForm"
      :options="filterData"
      @apply="applyFilter"
    ></budget-multiple-accounts-table-amounts-filter>

    <b-row class="bordered-table mt-3" no-gutters>
      <b-col cols="12" md="7" class="pl-3">
        <h2 class="text-gray">{{ $t( translationPath + 'table.amounts_title') }}</h2>
      </b-col>
      <b-col cols="12" md="5" class="pl-3 text-right">
        <b-button class="btn btn-sm" variant="outline-secondary" @click="$refs.FilterForm.toggle()"><i class="flaticon stroke zoom mr-2"></i> {{ $t(translationPath + 'amounts_filter.btn_filter') }}</b-button>
      </b-col>
      <b-col cols="12" class="px-3">
        <p class="text-gray">{{ $t( translationPath + 'table.amounts_description') }}</p>
        <p><b-button v-if="isAnySelected" class="btn" variant="danger" @click="showDeleteModal">{{ $t(translationPath + 'delete_selected') }}</b-button></p>
      </b-col>

      <b-col cols="12" class="px-3">
        <b-table
           class="spirecta-simple-table accounts-table" show-empty hover responsive striped
           :items="tableItems"
           :fields="fieldValues"
           :filter="tableFilter"
           :filter-included-fields="aColumnsToFilterOn"
           stacked="md"
           :emptyText="$t('incomes_expenses.accounts.common.no_data')"
           ref="budgetTableAmounts"
           :busy="busy"
        >
          <template v-slot:table-busy>
            <loader />
          </template>

          <template slot="top-row">
            <td colspan="6">
              <div class="d-flex align-items-center">
                <i class="fa fa-search"></i>
                <b-form-input v-model="tableFilter" size="sm" :placeholder="$t( 'incomes_expenses.accounts.common.' + ( currentUser.strict_accounting_mode ? 'account_filter_placeholder' : 'category_filter_placeholder'))" />
              </div>
            </td>
          </template>

          <template v-slot:head(checkbox)>
            <b-form-checkbox
              v-model="isAllSelected"
              @change="toggleSelectedAll"
            ></b-form-checkbox>
          </template>

          <template v-slot:cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.item.is_checked"
              @change="onItemCheck"
            ></b-form-checkbox>
          </template>

          <template v-slot:cell(account_title)="row">
            <b-link :to="`/budget/account/${row.item.account_id}` + ($route.query.bid ? ('?bid=' + $route.query.bid) : '')">{{ row.value }}</b-link>
          </template>

          <template v-slot:cell(actions)="row">
            <b-link class='btn plain-btn text-regular action-button open-link' :to="'/reports/performance/accounts/' + row.item.account_id + '/view'">
              <i class="flaticon solid right-circle-2 text-gray" style="color:#bcbdbe;"></i> <span>{{ $t('common.report')}}</span>
            </b-link>
            <b-link class='btn plain-btn text-regular action-button open-link' :to="`/budget/account/${row.item.account_id}/${row.item.date.split('-')[0]}` + ($route.query.bid ? ('?bid=' + $route.query.bid) : '')">
              <i class="flaticon solid edit-2 text-gray" style="color:#bcbdbe;"></i> <span>{{ $t('common.edit')}}</span>
            </b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <delete-modal
      ref="DeleteRowModal"
      :heading="$t('common.delete')"
      :message="$t(translationPath + 'delete_confirm_text')"
      @on-delete-confirm="deleteSelected"
    ></delete-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import titleMixins from '@/mixins/title'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import DeleteModal from '@/components/modals/DeleteModal'
import BudgetMultipleAccountsTableAmountsFilter from './BudgetMultipleAccountsTableAmountsFilter'

export default {
  name: 'BudgetMultipleAccountsTableAmounts',
  mixins: [titleMixins],
  components: { Loader, DeleteModal, BudgetMultipleAccountsTableAmountsFilter },
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'budget.multiple_accounts.',
      tableItems: [],
      existedBudgetYears: [],
      isAllSelected: false,
      filter: { date: null, account_id: null },
      tableFilter: '',
      aColumnsToFilterOn: ['account_title', 'date', 'amount'],
      busy: true
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'checkbox', label: '' },
        { key: 'date', label: this.$t('common.date'), sortable: true },
        { key: 'account_title', label: this.currentUser.strict_accounting_mode ? this.$t('common.account') : this.$t('common.category'), sortable: true },
        { key: 'amount', label: this.$t('common.amount'), class: 'text-right', sortable: true },
        { key: 'actions', label: '', class: 'text-right' }
      ]
    },
    isAnySelected () {
      return this.tableItems.filter(i => i.is_checked).length
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    filterData () {
      const data = { years: this.existedBudgetYears }
      return data
    }
  },
  methods: {
    loadData () {
      this.busy = true
      this.tableItems = []
      const params = {
        account_type: this.filter.account_type,
        account_id: this.filter.account_id,
        year: this.filter.year,
        month: this.filter.month,
        budget_id: this.$route.query.bid ? this.$route.query.bid : this.currentCOA.default_budget_id
      }
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts`, { params: params })
        .then(response => {
          response.data.data.map(item => {
            this.tableItems.push({
              id: item.id,
              amount: formatAmount(
                item.amount,
                this.currentCOA.locale,
                this.currentCOA.currency,
                this.currentCOA.currency_iso,
                true,
                2
              ),
              account_id: item.account_id,
              account_title: item.account.title,
              date: item.year + '-' + (item.month < 10 ? '0' : '') + item.month,
              is_checked: false
            })
            this.existedBudgetYears.push(item.year)
          })
          this.existedBudgetYears = this.existedBudgetYears.filter((value, index, self) => self.indexOf(value) === index)
        })
        .catch(error => {
          console.error(error)
        })
        .then(() => {
          if (!this.filter.year) {
            this.$emit('year', 'all_years')
          }
          this.busy = false
        })
    },
    toggleSelectedAll (val) {
      this.tableItems.map(item => {
        item.is_checked = val
      })
    },
    onItemCheck (val) {
      const cntAllItems = this.tableItems.length
      let cntCheckedItems = this.tableItems.filter(i => i.is_checked).length
      if (val) {
        cntCheckedItems++
      } else {
        cntCheckedItems--
      }

      if (cntAllItems === cntCheckedItems) {
        this.isAllSelected = true
      } else {
        this.isAllSelected = false
      }
    },
    showDeleteModal () {
      this.$refs.DeleteRowModal.show()
    },
    deleteSelected () {
      this.$refs.DeleteRowModal.hide()
      const data = { ids: [] }
      this.tableItems.filter(i => i.is_checked).map(o => {
        data.ids.push(o.id)
      })
      axios.delete(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/delete-multiple`, { data: data })
        .then(response => {
          if (response.data.data.is_delete_success) {
            this.tableItems = this.tableItems.filter(i => !i.is_checked)
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_multiple_success_message'), {
              title: this.$t(this.translationPath + 'toast.title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          } else {
            throw new Error('No entries have been deleted')
          }
        })
        .catch(error => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_multiple_fail_message'), {
            title: this.$t(this.translationPath + 'toast.title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    applyFilter (filter) {
      this.filter = filter
      if (filter.budget_id && Number(filter.budget_id) !== Number(this.$route.query.bid)) {
        this.$router.push({ query: { bid: filter.budget_id } })
      }
      this.$emit('year', filter.year)
      this.loadData()
    }
  },
  created () {
    if (this.currentCOA.locale) {
      this.loadData()
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    year () {
      this.loadData()
    }
  }
}
</script>
